.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


.App-link {
  color: #61dafb;
}
.piracybarcode {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.findbook h4 {
  margin-bottom: 10px;
  font-size: larger;
}

.findbooksearch input[type="text"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.search-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
}

.verified {
  margin-top: 20px;
}

.verified p {
  margin: 0;
}

.green {
  color: green;
}

.red {
  color: red;
}

#pira {
  margin-top: 20px;
}

#distri {
  margin-top: 20px;
}
@media only screen and (max-width: 768px) {
  .piracybarcode {
    padding: 10px;
  }

  .findbooksearch input[type="text"] {
    margin-bottom: 5px;
  }

  .search-button {
    font-size: 14px;
    padding: 8px 16px;
  }

  #pira,
  #distri {
    margin-top: 10px;
  }
}
.app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.qr-reader {
  max-width: 100%;
  height: auto;
  border: 2px solid #007bff;
  border-radius: 8px;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .qr-reader {
    width: 90vw; /* Adjust based on viewport width */
    height: auto; /* Maintain aspect ratio */
  }
}

.verified {
  margin-top: 20px;
  font-size: 1.2rem; /* Larger text for better readability */
}

#pira, #distri {
  font-size: 1rem; /* Adjust font size for consistency */
  margin-top: 10px;
}

.start-scan-btn {
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;
}

.start-scan-btn:hover {
  background-color: #0056b3;
}

video:nth-child(2) {
  display: none!important;
}

#html5qr-code-full-region {
  width: 100%;
  height: auto; /* Adjust based on the aspect ratio or set a fixed height */
  min-height: 300px; /* Provide a minimum height */
}
video:nth-child(2) {
  display: none!important;
}