.qr-reader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: filter 0.5s ease; /* Add transition for smoother blur effect */
}

.blurred .qr-reader {
  filter: blur(
    5px
  ); /* Blur camera feed when the container has the "blurred" class */
}

.scanned-barcode {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(
    255,
    255,
    255,
    0.8
  ); /* Semi-transparent white background */
  padding: 10px;
  border-radius: 5px;
}
