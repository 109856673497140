.piracybarcode {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.findbook h4 {
  margin-bottom: 10px;
  font-size: larger;
}

.findbooksearch input[type="text"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.search-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
}

.verified {
  margin-top: 20px;
}

.verified p {
  margin: 0;
}

.green {
  color: green;
}

.red {
  color: red;
}

#pira {
  margin-top: 20px;
}

#distri {
  margin-top: 20px;
}

/* Responsive styles */
@media only screen and (max-width: 768px) {
  .piracybarcode {
    padding: 10px;
  }

  .findbooksearch input[type="text"] {
    margin-bottom: 5px;
  }

  .search-button {
    font-size: 14px;
    padding: 8px 16px;
  }

  #pira,
  #distri {
    margin-top: 10px;
  }

  .PS {
    visibility: hidden;
  }
  .searchbar {
    visibility: hidden;
  }
}
.PS {
  visibility: hidden;
}
.searchbar {
  visibility: hidden;
}
.piracyhead {
  margin-left: 2rem;
  width: 26rem;
  margin-top: 2rem;
  font-size: 0.8rem;
}



.input-container {
  display: flex;
  align-items: center;
}

.input-container input[type="text"] {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 10px;
}

.input-container button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.input-container button:hover {
  background-color: #0056b3;
}

.input-container button:focus {
  outline: none;
}


.switch-mode-button {
  background-color: #007bff; 
  color: #fff; 
  border: none; 
  padding: 10px 20px; 
  font-size: 16px;
  cursor: pointer; 
  border-radius: 5px; 
}

.switch-mode-button:hover {
  background-color: #0056b3; 
}


